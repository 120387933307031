import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import pulplogonew from "../img/pulplitlogo.svg";
import bgContact from "../img/bgContact.avif";
import contactpump from "../img/contact.avifs";
import emailjs from "@emailjs/browser";
import Turnstile from "react-turnstile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  project_link: Yup.string().required("Project link is required"),
  telegram_address: Yup.string().required("Telegram address is required"),
});

function Contact() {
  const [showOverlay, setShowOverlay] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);

  // Calendly Widget Yükleme
  useEffect(() => {
    if (showOverlay) {
      const script = document.createElement("script");
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [showOverlay]);

  const sendEmail = (values, resetForm) => {
    if (!turnstileToken) {
      setCaptchaError(true);
      return;
    }

    setCaptchaError(false);

    emailjs
      .send(
        "service_yh9z14h",
        "template_c57yuuq",
        {
          name: values.name,
          project_link: values.project_link,
          telegram_address: values.telegram_address,
        },
        "VMvyT1z-rohdYMv1g"
      )
      .then(
        () => {
          toast.success(
            "Thank you for reaching out! We have received your information and will get back to you as soon as possible."
          );
          resetForm();
        },
        (error) => {
          toast.fail("Failed");
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div
      id="contact"
      className="relative w-full mobile:bg-pulpContact mobile:h-[50vh] h-screen md:h-[55vh] lg:h-screen flex items-center bg-white justify-center"
    >
      <img
        src={contactpump}
        alt="GIF"
        className="absolute right-0 mobile:mt-[-20px] top-0 mobile: w-full h-full mobile:object-contain object-fill transition-opacity duration-1000"
      />

      <button
        className="absolute mobile:mt-[15vh] mobile:left-[15vw] left-[10vw] mt-[10vh] md:left-[20vw] md:mt-[25vh] lg:mt-[50vh] cursor-pointer font-semibold rounded-full bg-orange-400 hover:bg-orange-500 text-white py-2 text-base md:text-xl 2xl:text-2xl px-5"
        onClick={() => setShowOverlay(true)}
      >
        PULP IT
      </button>
      <p className="absolute mobile:mt-[30vh] mobile:left-[10vw] left-[10vw] mt-[25vh] text-white md:left-[20vw] md:mt-[40vh] lg:mt-[75vh] tablet:w-2/4 lg:w-2/4 2xl:w-1/3 text-xs md:text-base">
        Pulp the new media with the award-winning team, where creativity meets
        excellence and innovation never ends.
      </p>

      <img
        src={bgContact}
        alt="GIF"
        className={`absolute inset-0 w-full h-full mobile:object-cover mobile:object-left lg:object-fill xl:object-fill 2xl:object-cover transition-opacity duration-1000 ${
          showOverlay ? "opacity-100 block" : "opacity-0 hidden"
        }`}
      />
      <div
        className={`absolute mobile:scale-50 container h-full p-4 ml-auto mr-auto flex flex-col md:flex-row justify-center md:justify-between items-center transition-opacity duration-1000 ${
          showOverlay ? "opacity-100 block" : "opacity-0 hidden"
        }`}
      >
        <div className="mo text-white text-center mb-8 md:mb-0 md:mr-10 md:w-1/2">
          <h2 className="text-5xl text-left flex justify-center text-black font-bold">
            PULP IT
          </h2>
          <div className="flex justify-center mt-10">
            <img src={pulplogonew} alt="" />
          </div>
        </div>

        <div className="w-full md:w-1/2 flex items-center justify-center">
          <div className="max-w-md w-full bg-opacity-100 p-0 lg-p- mobile:p-0 min-h-[250px] rounded-lg min-w-full lg-min-h-full bg-white tablet:scale-75 ">
            {showOverlay && (
              <div
                className="calendly-inline-widget min-h-[250px] p-0  "
                data-url="https://calendly.com/c-kestir-pulpmedia/60-minute-meeting-clone?hide_gdpr_banner=1&primary_color=ff9933"
                style={{
                  minwidth: "320px",
                  display: "flex",
                  height: "630px",
                  border: "none",
                  boxShadow: "none",
                  borderRadius: "10px",
                }}
              ></div>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Contact;

{
  /* <div className="w-full md:w-1/2 flex items-center justify-center">
          <div className="max-w-md w-full bg-opacity-75 p-8 rounded-lg">
            <Formik
              initialValues={{
                name: "",
                project_link: "",
                telegram_address: "",
              }}
              validationSchema={ContactSchema}
              onSubmit={(values, { resetForm }) => {
                sendEmail(values, resetForm);
              }}
            >
              {({ isSubmitting, touched, errors }) => (
                <Form className="grid grid-rows-5 gap-4">
                  <div>
                    <Field
                      type="text"
                      name="name"
                      className={`p-2 rounded-full border-2 ${
                        touched.name && errors.name
                          ? "border-red-500"
                          : "border-gray-300"
                      } focus:border-pulpPurple focus:outline-none placeholder-black w-full`}
                      placeholder="Name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div>
                    <Field
                      type="text"
                      name="project_link"
                      className={`p-2 rounded-full border-2 ${
                        touched.project_link && errors.project_link
                          ? "border-red-500"
                          : "border-gray-300"
                      } focus:border-pulpPurple focus:outline-none placeholder-black w-full`}
                      placeholder="Project Link"
                    />
                    <ErrorMessage
                      name="project_link"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div>
                    <Field
                      type="text"
                      name="telegram_address"
                      className={`p-2 rounded-full border-2 ${
                        touched.telegram_address && errors.telegram_address
                          ? "border-red-500"
                          : "border-gray-300"
                      } focus:border-pulpPurple focus:outline-none placeholder-black w-full`}
                      placeholder="Telegram"
                    />
                    <ErrorMessage
                      name="telegram_address"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <Turnstile
                    sitekey="0x4AAAAAAAenJVIxT0YfO9aN"
                    onVerify={(token) => {
                      setTurnstileToken(token);
                      setCaptchaError(false);
                    }}
                  />
                  {captchaError && (
                    <div className="text-red-500">
                      Please complete the CAPTCHA
                    </div>
                  )}
                  <button
                    type="submit"
                    className="rounded-full text-xl bg-orange-400 p-2 text-white hover:bg-orange-500 w-full"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>  */
}
